$(function() {
    // DOM
    var $filterRange = $('[data-rangeSlider]');
    var $rangeInputLeft = $('.js-range-input-left');
    var $rangeInputRight = $('.js-range-input-right');

    // Options

    $filterRange.ionRangeSlider({
        type: "round",
        hide_min_max: true,
        hide_from_to: true,
        onStart: function (data) {
            var { from,  to } = data;
            $rangeInputLeft.val(formatOutputValue(from));
            $rangeInputRight.val(formatOutputValue(to));
        },
        onChange: function(data) {
            var { from,  to } = data;
            $rangeInputLeft.val(formatOutputValue(from));
            $rangeInputRight.val(formatOutputValue(to));
        },
        onFinish: function(data) {
            var $tooltipRestultArea = $filterRange.parents('.js-tooltip-restult-area');
            var $tooltipResultClone = $('.js-tooltip-result').clone(true).addClass('tooltip-clone');
            
            $('.tooltip-clone').remove();
            $tooltipRestultArea.append($tooltipResultClone.show());

            timerResult = setTimeout(() => {
                $tooltipResultClone.hide();
            }, 4000);
        }
    });

    let my_range = $filterRange.data("ionRangeSlider");

    $('.js-reset-filter').on('click', function(e) {
        e.preventDefault();
        
        $rangeInputRight.val(formatOutputValue(700000));
        $rangeInputLeft.val(formatOutputValue(100000));
        
        my_range.reset();
    });

    $rangeInputLeft.focus(focusInputFormated);
    $rangeInputRight.focus(focusInputFormated);

    $rangeInputLeft.focusout(focusOutputFormated);
    $rangeInputRight.focusout(focusOutputFormated);

    function formatInputValue(value) {
        return value.replace(/[^0-9]/g, '').trim();
    }

    function formatOutputValue(value) {
        return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    }

    function focusInputFormated() {
        var value = this.value;
        var foramtValue = formatInputValue(value);
        this.value = foramtValue;
    }

    function focusOutputFormated() {
        var value = this.value;
        var foramtValue = formatOutputValue(value);
        this.value = foramtValue;
    }

    $('.js-show-filter').on('click', function(e) {
        e.preventDefault();
        $('.js-filter-modal').show();
    });

    
    $('.js-close-filter').on('click', function(e) {
        e.preventDefault();
        $('.js-filter-modal').hide();
    });
});