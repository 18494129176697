$(function() {
    function goTo(e) {
        e.preventDefault();
        var hash = $(this).attr('href');
        $('html, body').stop().animate({
        'scrollTop': $(hash).offset().top
        }, 800, 'swing');
    }
    
    $('.js-go-to').on('click', goTo);

    $(document).scroll(function() {
        if ($(document).scrollTop() > 800) {
            $('.js-go-up').show();
        } else {
            $('.js-go-up').hide();
        }
    });
});