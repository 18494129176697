/*
    Убираем видимое состояние фокуса до тех пор,
    пока не нажмётся клавиша TAB

    В CSS:
    html._no-visible-focus * {
        outline: 0 !important;
    }
*/

if (!App.mobile) {
    App.H.addClass('_no-visible-focus');

    App.W.on('keydown.tabfocus', function(e) {
        const keyCode = e.keyCode || e.which;

        if (keyCode == 9) {
            App.H.removeClass('_no-visible-focus');
            App.W.off('keydown.tabfocus');
        }
    });
}
