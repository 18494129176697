import './modules/App';
import './modules/TabFocus';
import './modules/Burger';
import './modules/Sliders';
import './modules/GoTo';
import './modules/Filter';
// import './modules/Rating';
import './modules/MobileFilter';
import './modules/Tooltips';
import './modules/SelectGroupInfo';
import './modules/Order';
// import './modules/CountProduct';
import './modules/AddNewAddress';
import './modules/LockScroll';

$(function () {

    $('.js-enter-account').on('click', function(e) {
        e.preventDefault();
        $.fancybox.close();
        $.fancybox.open({
            src: '#account-modal-enter',
            touch: false,
            autoFocus: false
        });
    });

    $('.js-creacte-account').on('click', function() {
        $.fancybox.close();
        $.fancybox.open({
            src: '#account-modal-auth',
            touch: false,
            autoFocus: false
        });
    });
    
    $('.js-recover-password').on('click', function() {
        $.fancybox.close();
        $.fancybox.open({
            src: '#account-modal-recover-password',
            touch: false,
            autoFocus: false
        });
    });

     $('.js-remembered-password').on('click', function() {
        $.fancybox.close();
        $.fancybox.open({
            src: '#account-modal-enter',
            touch: false,
            autoFocus: false
        });
    });

    $('.js-recovered-password').on('click', function() {
        $.fancybox.close();
        $.fancybox.open({
            src: '#account-modal-recovered',
            touch: false
        });
    });

    $('#review-rating').rateit({
        step: 1
    });

    if (window.matchMedia("(min-width: 1000px)").matches) {
        $('.zoom').zoom({ on: 'click' });
    }

    $('[data-phone]').mask('+7(000)000-00-00');

    $('[data-date]').mask('00.00.0000');

    $('#autocomplete').autocomplete({
        serviceUrl: '/autocomplete/countries',
        appendTo: $('.search-result__list'),
        minChars: 3,
        formatResult: function(suggestion, currentValue) {
            return `
                <a href="${suggestion.data}" class="search-result__link">
                    ${suggestion.value}
                </a>
            `;
        },
        lookup: function (query, done) {
            // Do Ajax call or lookup locally, when done,
            // call the callback and pass your results:
            const result = {
                suggestions: [
                    { "value": "витамины и минералы", "data": "single-product.html" },
                    { "value": "витамины и минералы для спортсменов", "data": "single-product.html" },
                    { "value": "витамины и добавки для кошек и собак", "data": "single-product.html" },
                    { "value": "витамины и добавки для кошек и собак Beaphar", "data": "single-product.html" },
                    { "value": "витамины и минералы SOLGAR", "data": "single-product.html" },
                    { "value": "витамины и минералы фармстандарт-уфавита", "data": "single-product.html" },
                    { "value": "витамины и минералы NOW", "data": "single-product.html" },
                    { "value": "витамины и минералы для детей", "data": "single-product.html" }
                ]
            };
    
            done(result);
        }
    });

    $('.js-select-city').on('click', function() {
        const $currentCity = $('.js-current-city');
        $('#select-city-modal-input').val($currentCity.text().trim());
        
        $.fancybox.open({
            src: '#select-city-modal',
            touch: false
        });
    });

    $('#select-city-modal-input').autocomplete({
        serviceUrl: '/autocomplete/countries',
        appendTo: $('.select-city-modal__list'),
        minChars: 3,
        formatResult: function(suggestion, currentValue) {
            return `
                <a href="#" class="select-city-modal__link">
                    <div class="select-city-modal__value">
                        ${suggestion.value}
                    </div>
                    <div class="select-city-modal__desc">
                        ${suggestion.desc}
                    </div>
                </a>
            `;
        }
    });

    function isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    $('.js-show-more-items').on('click', function() {
        const parent = $(this).parents('.js-category-sidebar-list');
        const items = parent.find('.js-category-sidebar-item');
        items.toggleClass('_hide');
    });

    $(window).scroll(showStickyBlock);

    function showStickyBlock() {
        const $breakpoint   = $('.js-breakpoint');
        if ($('div').is('.js-breakpoint')) {
            const $stickyFooter = $('.js-sticky-footer');
            const scrollTop     = App.W.scrollTop();
            const elementOffset = $breakpoint.offset().top;
            const distance      = (elementOffset - scrollTop);

            if (distance <= 0) {
                $stickyFooter.css('opacity', 1);
            } else {
                $stickyFooter.css('opacity', 0);
            }
        }
    }

    showStickyBlock();

    (function setProgressBarValue() {
        const $progressBar = $('.js-special-offer-progress-bar');
        const fullValue = $progressBar.data('full-value');
        const currentValue = $progressBar.data('curr-value');

        const percent = fullValue / 100;
        const progressWidth = currentValue / percent;

        $progressBar.width(progressWidth + '%');
    })();


    $('.js-product-gallery').on('click', function (e) {
        e.preventDefault();
        const $target = $(e.target).parent();

        if (!$target.hasClass('js-product-gallery-thumb')) {
            return false;
        }

        $('.js-product-gallery-thumb').removeClass('_is-active');
        $target.addClass('_is-active');

        const $mainImage = $('.js-product-gallery-main-image');
        const href = $target.attr('href');
        const bg = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        const img = document.createElement('img');

        $mainImage.attr('src', bg);

        $mainImage.removeClass('lazyloaded').addClass('lazyload');
        $($mainImage.parents('.product-gallery__main-image')).removeClass('lazyloaded').addClass('lazyload');
        
        img.src = href;
        
        img.onload = function () {
            $mainImage.attr('src', href);
            $mainImage.removeClass('lazyload').addClass('lazyloaded');
            $($mainImage.parents('.product-gallery__main-image')).removeClass('lazyload').addClass('lazyloaded');
        };
    });

    $('.js-product-card-thumb').on('click', function (e) {
        e.preventDefault();
        const parent = $(this).parents('.product-card');
        const $mainImage = parent.find('.js-product-card-main-image');
        const href = $(this).attr('href');

        $mainImage.attr('src', href);
    });

    const $textareas = $('.js-input-line-textarea');
    $textareas.on('keyup', function() {
        const currentHeight = $(this).height();
        const scrollTop = $(this).scrollTop();
        $(this).height(scrollTop + currentHeight);
    });

    $('.js-accordion-trigger').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('_is-active');
    });

    $('.js-land-accardeon-trigger').on('click', function(e) {
        e.preventDefault();
        const parent = $(this).parent();
        $(parent).toggleClass('_is-active');
    });

    
    $('.js-focus').focus(foucusedInput);
    $('.js-focus').focusout(focuseOutInput);
    $('.js-focus').each(focusedInit);

    function focusedInit() {
        const field = $(this);
        const parent = field.parents('.js-input-parent');

        field.val() && parent.addClass('_focused');
        parent.removeClass('_error');
        parent.find('.js-error-mess').hide();
    }

    function foucusedInput() {
        const field = $(this);
        const parent = field.parents('.js-input-parent');
        
        parent.addClass('_focused');
    }
    
    function focuseOutInput() {
        const field = $(this);
        const parent = field.parents('.js-input-parent');
        
        !field.val() ? parent.removeClass('_focused') : parent.addClass('_focused');
        // field.data('required') !== undefined && validateInput(field);
    }

    function validateInput(field) {
        const parent = field.parents('.js-input-parent');

        if (!field.val().length) {
            parent.addClass('_error');
            parent.find('.js-error-mess').show();
        } else {
            parent.removeClass('_error').addClass('_success');
            parent.find('.js-error-mess').hide();
        }
    }

    $('[data-phone]').on('input', function () {
        const val = $(this).val().replace(/[^0-9\s()+-]/g, '');
        $(this).val(val);
    });

    $('.js-close-login-tile').on('click', function(e) {
        e.preventDefault();
        $(this).parents('.js-login-tile').hide();
    });

    $('.js-order-way').on('click', function(e) {
        e.preventDefault();

        App.lockScroll.lock();

        $.fancybox.close();
        $.fancybox.open({
            src: '#order-info-modal-way',
            touch: false,
            closeBtn: false
        });
    });

    $('.js-order-cancel').on('click', function(e) {
        e.preventDefault();

        App.lockScroll.lock();

        $.fancybox.close();
        $.fancybox.open({
            src: '#order-info-modal-reason',
            touch: false,
            closeBtn: false
        });
    });

    $('.js-order-status').on('click', function(e) {
        e.preventDefault();

        App.lockScroll.lock();

        $.fancybox.close();
        $.fancybox.open({
            src: '#order-info-modal-status',
            touch: false,
            closeBtn: false
        });
    });

    $('.js-order-info-close').on('click', function(e) {
        e.preventDefault();

        App.lockScroll.unlock();
        $.fancybox.close();
    });


    $(document).on('afterClose.fb', function( e, instance, slide ) {
        App.lockScroll.unlock();
    });

    $('.js-btn-support').on('click', function(e) {
        e.preventDefault();

        App.lockScroll.lock();
        $.fancybox.open({
            src: '#support-modal',
            touch: false,
            closeBtn: false,
            afterClose: function() {
                App.lockScroll.unlock();
            }
        });
    });

    $('.js-submenu-trigger').on('click', function(e) {
        e.preventDefault();

        const $parent = $(this).parent();
        
        $('.faq-navigation__item._current').removeClass('_current');

        $parent.toggleClass('_current');
    });
});