$(function () {
    var bannerSlider = new Swiper('.js-banner-slider', {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        lazy: true,
        pagination: {
            el: '.js-banner-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.js-promotions-banner-next',
            prevEl: '.js-promotions-banner-prev',
        },
    });

    var productSlider = new Swiper('.js-product-slider', {
        slidesPerView: 'auto',
        // spaceBetween: 16,
        lazy: true,
        on: {
            init: function () {
                var that = this;

                $(this.el.offsetParent).on('click', '.js-product-slider-next', function () {
                    that.slideNext();
                    var isEnd = that.isEnd;
                    var isBegining = that.isBeginning;
                    isEnd && $(this).addClass('_desabled');
                    !isBegining && $(that.el.offsetParent).find('.js-product-slider-prev').removeClass('_desabled');
                });

                $(this.el.offsetParent).on('click', '.js-product-slider-prev', function () {
                    that.slidePrev();
                    var isEnd = that.isEnd;
                    var isBegining = that.isBeginning;
                    !isEnd && $(that.el.offsetParent).find('.js-product-slider-next').removeClass('_desabled');
                    isBegining && $(this).addClass('_desabled');
                });

            }
        }
    });

    var categorySlider = new Swiper('.js-category-slider', {
        slidesPerView: 'auto',
        spaceBetween: 16,
        init: false,
        on: {
            init: function () {
                var that = this;

                $(this.el.offsetParent).on('click', '.js-category-slider-next', function () {
                    that.slideNext();
                    var isEnd = that.isEnd;
                    var isBegining = that.isBeginning;
                    isEnd && $(this).addClass('_desabled');
                    !isBegining && $(that.el.offsetParent).find('.js-category-slider-prev').removeClass('_desabled');
                });

                $(this.el.offsetParent).on('click', '.js-category-slider-prev', function () {
                    that.slidePrev();
                    var isEnd = that.isEnd;
                    var isBegining = that.isBeginning;
                    !isEnd && $(that.el.offsetParent).find('.js-category-slider-next').removeClass('_desabled');
                    isBegining && $(this).addClass('_desabled');
                });

            }
        }
    });

    var productCardSlider = new Swiper('.js-product-card-slider', {
        slidesPerView: 'auto',
        direction: 'vertical',
        spaceBetween: 8,
        watchOverflow: true,
        on: {
            init: function () {
                var that = this;
                var slidesHeight = 0;

                $(this.el.offsetParent).on('click', '.js-product-card-down', function () {
                    that.slideNext();
                });

                $(this.el.offsetParent).on('click', '.js-product-card-up', function () {
                    that.slidePrev();
                });
                
                Array.prototype.forEach.call(that.slidesSizesGrid, function(height) {
                    slidesHeight += height;
                });

                if (slidesHeight > that.height) {
                    $(that.$el).parents('.product-card__slider').find('.product-card__control').show();
                    
                }
                
            }
        }
    });

    window.productGallery = new Swiper('.js-product-gallery', {
        slidesPerView: 1,
        spaceBetween: 16,
        lazy: true,
        breakpoints: {
            600: {
                slidesPerView: 'auto',
                direction: 'vertical',
                effect: 'slide',
                spaceBetween: 16,
                fadeEffect: '',
                init: true
            }
        },
        pagination: {
            el: '.js-product-gallery-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.js-product-gallery-down',
            prevEl: '.js-product-gallery-up',
        },
    });

    var galleryThumbs = new Swiper('.js-gallery-modal-thumbs', {
        spaceBetween: 16,
        freeMode: true,
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        mousewheel: true,
        breakpoints: {
            1000: {
                direction: 'vertical',
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
            }
        },
    });

    var galleryTop = new Swiper('.js-gallery-modal-main', {
        spaceBetween: 10,
        slidesPerView: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        thumbs: {
            swiper: galleryThumbs
        },
        navigation: {
            nextEl: '.js-gallery-modal-next',
            prevEl: '.js-gallery-modal-prev',
        },
    });

    var orderPageSlider = new Swiper('.js-order-page-slider', {
        autoHeight: true,
        allowTouchMove: false,
        on: {
            init: function () {
                var that = this;
                $('.js-order-page-next-slide').on('click', function (e) {
                    e.preventDefault();
                    that.slideNext();
                    var isBegining = that.isBeginning;
                    isBegining ? $('.js-step-back').hide() : $('.js-step-back').show();;
                });

                $('.js-step-back').on('click', function (e) {
                    e.preventDefault();
                    that.slidePrev();
                    var isBegining = that.isBeginning;
                    !isBegining && $(this).show();
                });

                $('.order-head__step-counter').find('.to').html(this.activeIndex + 1);
                $('.order-head__step-counter').find('.from').html(this.slides.length);
                
            },
            slideChangeTransitionEnd: function() {
                $('.order-head__step-counter').find('.to').html(this.activeIndex + 1);
                $('.order-head__step-counter').find('.from').html(this.slides.length);
            }
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
        }
    });

    $('.js-go-first-step').on('click', function (e) {
        e.preventDefault();

        orderPageSlider.slideTo(0);
    });

    var orderDays = new Swiper('.js-order-days', {
        spaceBetween: 0,
        freeMode: true,
        slidesPerView: 'auto'
    });

    $('[data-src="#add-to-card-modal"]').fancybox({
        afterShow: function (instance, current) {
            productSlider.forEach(function (item) {
                item.update();
            });
        }
    });

    $('[data-src="#gallery-modal"]').fancybox({
        afterLoad: function (instance, current) {
            galleryThumbs.update();
            galleryTop.update();
        }
    });

    $('.js-product-gallery-slide').on('click', function() {
        if ($(window).width() < 600) {
            $.fancybox.open( $('#gallery-modal'), {
                touch: false,
                afterLoad: function (instance, current) {
                    galleryThumbs.update();
                    galleryTop.update();
                }
            });
            
            galleryTop.slideTo($(this).index());
        }
    });

    var mql = window.matchMedia('all and (min-width: 1000px)');

    function updateCategorySlider() {
        mql.matches ? categorySlider.init() : categorySlider.destroy();
    }

    function updateorderPageSlider() {
        mql.matches ? orderPageSlider.destroy() : orderPageSlider.init();
    }

    if (document.querySelector('.js-category-slider')) {
        updateCategorySlider();
        $(window).on('resize', updateCategorySlider);
    }

    if (document.querySelector('.js-order-page-slider')) {
        updateorderPageSlider();
        $(window).on('resize', updateorderPageSlider);
    }
    
});