$(function() {
    $('.js-reset-filter').on('click', function() {
        $('.js-tooltip-result').hide();
        $('.js-tooltip-hint').hide();
    });

    let timerResult;

    $('.js-filter-sidebar').on('change', function(e) {
        clearTimeout(timerResult);

        const $target = $(e.target).not('[type="range"]');
        const $tooltipRestultArea = $target.parents('.js-tooltip-restult-area');
        const $tooltipResultClone = $('.js-tooltip-result').clone(true).addClass('tooltip-clone');
        
        $('.tooltip-clone').remove();
        $tooltipRestultArea.append($tooltipResultClone.show());

        timerResult = setTimeout(() => {
            $tooltipResultClone.hide();
        }, 4000);
    });
    
    let timerColor;

    $('[data-color]').on('change', function() {
        clearTimeout(timerColor);

        const dataColor = $(this).data('color');
        const $tooltipHintClone = $('.js-tooltip-hint').clone(true).addClass('hint-clone');
        const $parent = $(this).parent();

        $tooltipHintClone.text(dataColor);
        $('.hint-clone').remove();
        $parent.append($tooltipHintClone.show());

        timerColor = setTimeout(() => {
            $tooltipHintClone.hide();
        }, 4000);
    });
});