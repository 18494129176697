$(function () {
    const DOM = {
        $accountGroupTemplate: null,
        $addDddress: $('.js-add-address'),
        $addressList: $('.js-address-list'),
        $map: null,
        $selectCity: $('#selectCity'),
        $selectStreet: $('#selectStreet'),
        $selectHouse: $('#selectHouse'),
        $selectAppartament: $('#selectAppartament'),
        $selectIndex: $('#selectIndex')
    };

    const state = {
        indexCurrentForm: null
    };

    DOM.$addDddress.on('click', function (e) {
        e.preventDefault();

        DOM.$accountGroupTemplate = $('.js-account-group').first().clone(true, true);
        DOM.$addressList.append(DOM.$accountGroupTemplate);
        DOM.$accountGroupTemplate.find('.js-input').each((index, input) => {
            $(input).parent().removeClass('_focused');
        });

        showDeletBtn();
        setNumberAddress();
        setInputAttributes();
        resetFormAddress(DOM.$accountGroupTemplate);
        addListners(DOM.$accountGroupTemplate);
    });

    $('.js-select-address-btn').on('click', function (e) {
        e.preventDefault();
    });

    if ($('div').is('#account-map')) {
        const mapScript = $.getScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU');
        $.when(mapScript)
        .done(function () {

            ymaps.ready(init);

            function init() {

                $('.js-select-address-btn').on('click', function() {
                    state.currentForm = $(this).parents('.js-account-group').index();

                    const center = [52.273180, 104.288889];

                    let map = new ymaps.Map("account-map", {
                        center: center,
                        zoom: 14,
                        controls: []
                    });

                    $.fancybox.open({
                        src: '#account-select-address-modal',
                        touch: false,
                        afterShow: function () {

                            ymaps.geolocation.get({
                                provider: 'yandex',
                                autoReverseGeocode: true
                            }).then(function (data) {
                                const result = data.geoObjects.get(0);
                                const city = result.properties.getAll().name;
                                const coords = result.geometry.getCoordinates();
                                const placemark = setPlacemark(coords);

                                map.setCenter(coords, 13);
                                map.geoObjects.add(placemark);

                                DOM.$selectCity.val(city).trigger('focus');
                            });

                            map.events.add('click', (e) => handleMapClick(e, map));
                            $('.js-use-data').on('click', (e) => handleUseDataClick(e));

                        },
                        afterClose: function (modal) {
                            map.destroy();
                            map = null;
                            resetFormAddress($(modal.current.src));
                        }
                    });

                });

            }

            function handleUseDataClick(e) {
                e.preventDefault();
                const currentForm = DOM.$addressList.find('.js-account-group')[state.currentForm];

                $(currentForm).find('.js-input-city').val(DOM.$selectCity.val()).trigger('focus');
                $(currentForm).find('.js-input-street').val(DOM.$selectStreet.val()).trigger('focus');
                $(currentForm).find('.js-input-house').val(DOM.$selectHouse.val()).trigger('focus');
                $(currentForm).find('.js-input-appartament').val(DOM.$selectAppartament.val()).trigger('focus');
                $(currentForm).find('.js-input-index').val(DOM.$selectIndex.val()).trigger('focus');

                $.fancybox.close();
            }

            function handleMapClick(e, map) {
                const coords = e.get('coords');
                const placemark = setPlacemark(coords);

                map.geoObjects.removeAll();
                map.setCenter(coords);
                map.geoObjects.add(placemark);

                ymaps.geocode(coords, {
                    results: 1
                }).then(function (res) {
                    const adressLine = res.geoObjects.get(0).properties.get('text');
                    const [country, city, street, house] = adressLine.split(', ');

                    DOM.$selectCity.val(city).trigger('focus');
                    DOM.$selectStreet.val(street).trigger('focus');
                    DOM.$selectHouse.val(house).trigger('focus');
                });
            }

            function setPlacemark(coords) {
                const placemark = new ymaps.Placemark(coords, {}, {
                    iconLayout: 'default#image',
                    iconImageHref: 'img/svg/pin-2.svg',
                    iconImageSize: [42, 46],
                    // iconImageOffset: [-25, -70]
                });

                return placemark;
            }
        });
    }
    

    function resetFormAddress(form) {
        form.find('input').each((index, input) => {
            $(input).val('').trigger('focusout');
        });
    }

    function showDeletBtn() {
        if ($('.js-account-group').length > 1) {
            $('.js-delete-address').each((index, btn) => {
                $(btn).show();
                $(btn).on('click', (e) => deleteAddress(e));
            });
        } else {
            hideDeletBtn();
        }
    }

    function hideDeletBtn() {
        DOM.$addressList.find('.js-account-group').each((index, group) => {
            const btn = $(group).find('.js-delete-address');
            btn.hide();
        });
    }

    function addListners(form) {
        form.find('.js-focus').each((index, input) => {
            $(input).focus(foucusedInput);
            $(input).focusout(focuseOutInput);
        });
    }

    function foucusedInput() {
        const field = $(this);
        const parent = field.parents('.js-input-parent');
        parent.addClass('_focused');
    }

    function focuseOutInput() {
        const field = $(this);
        const parent = field.parents('.js-input-parent');
        !field.val() && parent.removeClass('_focused');
    }

    function deleteAddress(e) {
        e.preventDefault();

        const $parent = $(e.target).parents('.js-account-group');
        $parent.remove();
        setNumberAddress();
        showDeletBtn();
        setInputAttributes();
    }

    function setInputAttributes() {
        const forms = DOM.$addressList.find('.js-account-group');
        if (forms.length > 1) {
            forms.each((formIndex, form) => {
                $(form).find('.js-input').each((index, input) => {
                    const name = $(input).attr('data-name');
                    const label = $(input).parent().find('label');

                    $(input).attr('name', `address[${formIndex}][${name}]`);
                    $(input).attr('id', `address[${formIndex}][${name}]`);
                    label.attr('for', `address[${formIndex}][${name}]`);
                });
            });
        } else {
            $(forms).find('.js-input').each((index, input) => {
                const name = $(input).attr('data-name');
                const label = $(input).parent().find('label');

                $(input).attr('name', name);
                $(input).attr('id', name);
                label.attr('for', name);
            });
        }
    }

    function setNumberAddress() {
        DOM.$addressList.find('.js-number-addres').each((index, number) => $(number).text(index + 1));
    }
});