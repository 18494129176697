$(function() {
    $('body').on('click', '.js-show-more-address-back', function(e) {
        e.preventDefault();
        $.fancybox.close({
            src: 'show-more-address'
        });
        // scrollLock.enablePageScroll();
        App.lockScroll.unlock();
    });

    $('body').on('click', '.js-select-addess-modal-back', function(e) {
        e.preventDefault();
        $.fancybox.close({
            src: 'select-addess-modal'
        });
        App.lockScroll.unlock();
    });

    $('body').on('click', '.js-different-point', function(e) {
        e.preventDefault();
        $.fancybox.open({
            src: '#select-addess-modal',
            touch: false
        });
        App.lockScroll.lock();
    });

    $('body').on('change', '.js-obtaining', function(e) {
        var dataObtaining = $(this).data('obtaining');
        var obtainingItem = $('.js-obtaining-item');
        obtainingItem.each(function(index, item) {
            $(item).hasClass(dataObtaining) ? $(item).show() : $(item).hide();
        });
    });

    $('body').on('click', '.js-different-point-desctop', function(e) {
        e.preventDefault();
        $('.js-select-addess-card-list').show();
    });


    if ($('div').is('#order-map-result')) {
        var mapScript = $.getScript('https://api-maps.yandex.ru/2.1/?apikey=5f72f2c9-3431-47eb-a771-e356617870d3&lang=ru_RU');
        $.when(mapScript)
            .done(function () {
    
                ymaps.ready(init);
    
                function init() {
                    var center = [52.273180, 104.288889];
    
                    const resultAddress = $('.js-result-address').text();
                    const myGeocoder = ymaps.geocode(resultAddress);
                    const orderMapResult = new ymaps.Map("order-map-result", {
                        center: center,
                        zoom: 14,
                        controls: ['zoomControl']
                    });
    
                    orderMapResult.behaviors.disable('scrollZoom');
                    orderMapResult.behaviors.disable('drag');
    
                    if (window.matchMedia("(min-width: 1000px)").matches) {
                        orderMapResult.behaviors.enable('drag');
                    }
    
                    orderMapResult.events.add('click', function() {
                        orderMapResult.behaviors.enable('drag');
                    });
    
                    myGeocoder.then(function(res) {  
                        const resultCoords = res.geoObjects.get(0).geometry.getCoordinates();                    
                        const placemark = setPlacemark(resultCoords);
                    
                        orderMapResult.geoObjects.add(placemark);
                        orderMapResult.setCenter(resultCoords);
                    });
    
                    const selectAddessModalMap = new ymaps.Map("select-addess-modal-map", {
                        center: center,
                        zoom: 14,
                        controls: ['zoomControl']
                    });
    
                    const selectAddessCardMap = new ymaps.Map("select-addess-card-map", {
                        center: [55.729655, 37.617582],
                        zoom: 14,
                        controls: ['zoomControl']
                    });
    
                    const placemark = setPlacemark([55.729655, 37.617582]);
                    selectAddessCardMap.geoObjects.add(placemark);

                    $('body').on('click', '.js-select-delivery-point', function(e) {
                        e.preventDefault();
    
                        $.fancybox.close({ src: 'select-addess-modal' });
                        $.fancybox.close({ src: 'show-more-address' });
                        $('.js-select-addess-card-list').is(':visible') && $('.js-select-addess-card-list').hide();
                        App.lockScroll.unlock();
    
                        const parent = $(this).parents('.selected-addess');
                        const coords = parent.data('coords').split(', ');
                        const placemark = setPlacemark(coords);
                        const body = parent.find('.selected-addess__body');
                        const currentSelectedAddress = $('.js-current-selected-address');
    
                        currentSelectedAddress.find('.selected-addess__body').html(body.clone());
                        currentSelectedAddress.data('coords', coords.join(', '));
                        
                        selectAddessCardMap.geoObjects.removeAll();
                        selectAddessCardMap.geoObjects.add(placemark);
                        selectAddessCardMap.setCenter(coords, 13);
                    });

                    $('body').on('click', '.js-show-more-address', function(e) {
                        e.preventDefault();
                        $.fancybox.open({ src: '#show-more-address', touch: false });
                        if (!window.matchMedia("(min-width: 1000px)").matches) {
                            App.lockScroll.lock();
                        }
                        
                        const parent = $(this).parents('.selected-addess');
                        const coords = parent.data('coords').split(', ');
                        const placemark = setPlacemark(coords);
                    
                        selectAddessModalMap.geoObjects.removeAll();
                        selectAddessModalMap.geoObjects.add(placemark);
                        selectAddessModalMap.setCenter(coords, 13);
                    });
    
                }
    
                function setPlacemark(coords) {
                    const placemark = new ymaps.Placemark(coords, {}, {
                        iconLayout: 'default#image',
                        iconImageHref: 'img/svg/pin.svg',
                        iconImageSize: [42, 46],
                        // iconImageOffset: [-25, -70]
                    });
    
                    return placemark;
                }
            });
    }

    
});