window.App = {
    W: $(window),
    D: $(document),
    H: $('html'),
    B: $('body'),
    ie: false,
    edge: false,
    firefox: false,
    mainpage: false
};


