$(function() {

    $('.js-reset-filter').on('click', function(e) {
        e.preventDefault();
        const $parentSidebar = $('.js-filter-sidebar');
        const checkbox = $parentSidebar.find('input[type="checkbox"]');
        const radio = $parentSidebar.find('input[type="radio"]');
        
        [...checkbox].forEach(function(checkbox) {
            $(checkbox).prop('checked', false);
        });

        [...radio].forEach(function(radio) {
            $(radio).prop('checked', false);
        });
    });

});