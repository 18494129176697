$(function () {
    const $burger = $('.js-burger');
    $burger.on('click', function () {
        // DOM
        const $self = $(this);
        const $openSpans = $('.burger__open').find('span');
        const $closeSpans = $('.burger__close').find('span');
        const $catalogDesctop = $('.js-catalog-desctop');

        $self.toggleClass('is-active');

        // Params
        const animateTimeout = 100;
        const hasActiveClass = $self.hasClass('is-active');

        // hasActiveClass ? showMenu() : hideMenu();

        if (hasActiveClass) {
            $openSpans.each((index, span) => animateWidth(span, 0));
            setTimeout(() => $closeSpans.each((index, span) => animateWidth(span, '100%')), animateTimeout);
            $catalogDesctop.show();
            $('body').append('<div class="overlay js-overlay"></div>');
            
        } else {
            $closeSpans.each((index, span) => animateWidth(span, 0));
            setTimeout(() => $openSpans.each((index, span) => animateWidth(span, '100%')), animateTimeout);
            $catalogDesctop.hide();
            $('.overlay').remove();
        }

        function animateWidth(element, value) {
            $(element).animate({
                width: value
            });
        }
    });

    $('body').on('click', '.js-overlay', function() {
        $burger.trigger('click');
    });

    $('.js-catalog-nav-link').hover(function() {
        var $linkIndex = $(this).parent().index();
        var tabContent = $('.js-catalog-tab-content')[$linkIndex];

        $('.js-catalog-nav-link').removeClass('_hover');
        $(this).addClass('_hover');
        $('.js-catalog-tab-content').removeClass('_show');
        
        tabContent !== undefined && $(tabContent).addClass('_show');
    });

    $('.js-faq-sidebar-trigger').on('click', function () {
        // DOM
        $.fancybox.open({
            src: '#faq-page-sidebar'
        });
    });
});